import { lazy, Suspense } from 'react';

const SuspendedLoadSentry = lazy(() => import('./LoadSentry'));

export default function SentryMonitoring() {
  if (
    process.env.REACT_APP_ENV !== 'production' &&
    process.env.REACT_APP_ENV !== 'integration'
  ) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <SuspendedLoadSentry />
    </Suspense>
  );
}
