import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import Support from './pages/support/SuspendedSupportDetail';
import SuspendedSettings from './pages/settings/SuspendedSettings';
import SuspendedCompanySettings from './pages/company/SuspendedCompanySettingsPage';
import CurrencySettings from './pages/currency-settings/SuspendedCurrencySettingsPage';
import SuspendedBillingSettingsPage from './pages/billing/SuspendedBillingSettingsPage';
import SuspendedStep3SetupPage from './pages/appSetup/step_3/SuspendedStep3SetupPage';
import SuspendedStep4SetupPage from './pages/appSetup/step_4/SuspendedStep4SetupPage';
import SuspendedStep1SetupPage from './pages/appSetup/step_1/SuspendedStep1SetupPage';
import SuspendedPricingPage from './pages/appPricing/PricingPage/SuspendedPricingPage';
import SuspendedPricingOfferPage from './pages/appPricing/PricingOffer/SuspendedPricingOfferPage';
import SuspendedDiscountSettingsPage from './pages/discount-settings/SuspendedDiscountSettingsPage';
import SuspendedShippingSettingsPage from './pages/shipping/SuspendedShippingSettingsPage';
import SuspendedStep2SetupPage from './pages/appSetup/step_2/SuspendedStep2SetupPage';
import SuspendedEmailSettingsPage from './pages/email-settings/SuspendedEmailSettingsPage';
import SuspendedTaxSettingsPage from './pages/tax-settings/SuspendedTaxSettingsPage';
import SuspendedLanguageSettingsPage from './pages/language/SuspendedLanguageSettingsPage';
import SuspendedDesignSettingsPage from './pages/design/SuspendedDesignSettingsPage';
import SuspendedDashboardPage from './pages/dashboard/SuspendedDashboardPage';
import SuspendedAnalyticsPage from './pages/analytics/Index';
import LoadingCom from './components/LoadingCom';
import SuspendedEditInvoicePage from './pages/edit-invoice/SuspendedEditInvoicePage';
import SuspendedFulfillmentsPage from './pages/fulfillments/SuspendedFulfillmentsPage';
import SuspendedFulfillmentDashboardPage from './pages/fulfillment-dashboard/SuspendedFulfillmentDashboardPage';
import SentryMonitoring from './components/SentryMonitoring';

function App() {
  return (
    <div>
      <Suspense fallback={<LoadingCom />}>
        <Routes>
          <Route
            path='/pricing-offers'
            element={<SuspendedPricingOfferPage />}
          />
          <Route path='/pricing' element={<SuspendedPricingPage />} />
          <Route path='/settings'>
            <Route index element={<SuspendedSettings />} />
            <Route path='currency-settings' element={<CurrencySettings />} />
            <Route path='billing' element={<SuspendedBillingSettingsPage />} />
            <Route path='company' element={<SuspendedCompanySettings />} />
            <Route
              path='email-settings'
              element={<SuspendedEmailSettingsPage />}
            />
            <Route
              path='discount'
              element={<SuspendedDiscountSettingsPage />}
            />
            <Route
              path='shipping-settings'
              element={<SuspendedShippingSettingsPage />}
            />
            <Route
              path='language-settings'
              element={<SuspendedLanguageSettingsPage />}
            />
            <Route
              path='design-settings'
              element={<SuspendedDesignSettingsPage />}
            />
            <Route path='tax-settings' element={<SuspendedTaxSettingsPage />} />
          </Route>
          <Route path='invoices' element={<SuspendedDashboardPage />} />
          <Route
            path='fulfillments'
            element={<SuspendedFulfillmentDashboardPage />}
          />
          <Route path='step1' element={<SuspendedStep1SetupPage />} />
          <Route path='step2' element={<SuspendedStep2SetupPage />} />
          <Route path='step3' element={<SuspendedStep3SetupPage />} />
          <Route path='step4' element={<SuspendedStep4SetupPage />} />
          <Route path='pricing' element={<SuspendedPricingPage />} />
          <Route path='/support' element={<Support storeName='xyz' />} />
          <Route index element={<SuspendedAnalyticsPage />} />
          <Route
            path='orders/:orderId/edit'
            element={<SuspendedEditInvoicePage />}
          />
          <Route
            path='/orders/:orderId/order-fulfillments'
            element={<SuspendedFulfillmentsPage />}
          />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </Suspense>
      <SentryMonitoring />
    </div>
  );
}

export default App;
